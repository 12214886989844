import styles from './OfferScores.module.scss';
import { OfferItemInfo } from '../../interfaces/filter-item.interface';

export const OfferScores = ({ ...offer }: OfferItemInfo) => {
  return (
    <div className={styles.table}>
      <div className={`${styles.row} ${styles.titleRow}`}>
        <div>На бюджет</div>
        <div>На контракт</div>
        <div>Рік</div>
      </div>
      <div className={styles.row}>
        <div>{offer.enrolled_budget_score_2021 ? offer.enrolled_budget_score_2021 : '-'}</div>
        <div>{offer.enrolled_contract_score_2021 ? offer.enrolled_contract_score_2021 : '-'}</div>
        <div>2021</div>
      </div>
    </div>
  );
};
