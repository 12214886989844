import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';

import { education_forms } from '../filters/education_forms';
import { regions } from '../filters/regions';
import { specialities } from '../filters/specialities';
import { sorting, SortingTypes } from '../filters/sorting';
import { education_bases, BACHELOR_BASE_ARRAY } from '../filters/education_bases';
import { basic } from '../../../common/constants';
import { offer_types } from '../filters/offer_types';
import {
  FilterDataItem,
  FilterItem,
  IExtendedFilters,
  IFilterItem,
  IFilterItemExtended,
  IUpdatedFilters,
  ZnoScores,
  OfferItem,
  University,
} from '../interfaces/filter-item.interface';
import { FilterTypes, EXTENDED_FILTER_TYPES } from '../types/filter.type';
import { initFilterItem } from '../utils/init-filter-item.util';
import { resetFilterItem } from '../utils/reset-filter-item-util';
import { getIsFiltersDataChecked } from '../../../utils/getIsFiltersDataChecked';
import { groupOfferFiltersRequest } from '../utils/group-offer-filters-request';
import { getOffersBySubjectsCountReq, getOffersByZnosCountReq } from '../../../api/offers';
import { getParamsFromString } from '../utils/get-params-from-string';

export const UNIVERSITY_STORAGE_OFFERS_KEY = 'universityStorageOffers';
interface OffersContextProps {
  countOfOffers: number | undefined;
  filters: {
    sorting: FilterItem;
    education_forms: FilterItem;
    regions: FilterItem;
    specialities: FilterItem;
    offer_types: FilterItem;
    education_bases: FilterItem;
  };
  handleChangingOfFilters: (typeOfFilter: FilterTypes, updatedData: any) => void;
  selectedUniversities: number[];
  setSelectedUniversities: (prevState: number[]) => void;
  znoScores: ZnoScores;
  setZnoScores: (value: ZnoScores) => void;
  isOpenedZnoScoresFilterPopUp: boolean;
  isSortingByZnoScoresChecked: boolean;
  handleGettingResults: () => void;
  setIsOpenedZnoScoresFilterPopUp: (value: boolean) => void;
  isDisableShowMoreBtn: boolean;
  handleSelectOfZnoScores: (id: number, score: string | null, isCompleted?: boolean) => void;
  handleSelectOfZnoSubjects: (id: number) => void;
  handleClearingOfFilters: () => void;
  handleFiltersChip: () => void;
  filterParams: {
    education_forms: string[] | undefined;
    sorting: string[] | undefined;
    regions: string[] | undefined;
    specialities: string[] | undefined;
    offer_types: string[] | undefined;
    education_bases: string[] | undefined;
  };
  universitiesQueryValues: number[] | undefined;
  scoresQueryValues: { [key: string]: number };
  universities: University[];
  handleChangeOfSelectedUniversities: (updatedData: University) => void;
  resetFetchedUniversities: () => void;
  phrase: string;
  handleSetPhrase: (arg: string) => void;
  fetchUniversities: () => void;
  universityStorageOffers: OfferItem[];
  offers: OfferItem[];
  fetchOffers: () => void;
  handleGoBack: () => void;
  manageStorageUniversities: (card: OfferItem) => void;
  handleResetOffersAndOffersPage: () => void;
  selectedSubjects: number[];
  handleChangeOfSelectedSubjects: (id: number) => void;
}

// create context
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const OffersContext = createContext<OffersContextProps>();

interface Props {
  children: ReactNode;
}

const OffersContextProvider = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [savedSearchParams, setSavedSearchParams] = useState(localStorage.getItem('searchParams') || '');

  /**
   * MEMORIZES values
   * */
  const getDividedParams = (type: string) => searchParams.get(type)?.split(',');
  const filterParams = useMemo(
    () => ({
      education_forms: getDividedParams('education_forms')?.length
        ? getDividedParams('education_forms')
        : getParamsFromString('education_forms', savedSearchParams),
      sorting: getDividedParams('sorting')?.length
        ? getDividedParams('sorting')
        : getParamsFromString('sorting', savedSearchParams),
      regions: getDividedParams('regions')?.length
        ? getDividedParams('regions')
        : getParamsFromString('regions', savedSearchParams),
      specialities: getDividedParams('specialities')?.length
        ? getDividedParams('specialities')
        : getParamsFromString('specialities', savedSearchParams),
      offer_types: getDividedParams('offer_types')?.length
        ? getDividedParams('offer_types')
        : getParamsFromString('offer_types', savedSearchParams),
      education_bases: getDividedParams('education_bases')?.length
        ? getDividedParams('education_bases')
        : BACHELOR_BASE_ARRAY,
    }),
    [searchParams],
  );

  const scoresQueryValues = useMemo(
    () =>
      Object.keys(JSON.parse(searchParams.get('scores') || '{}')).length
        ? JSON.parse(searchParams.get('scores') || '{}')
        : getParamsFromString('scores', savedSearchParams),
    [searchParams],
  );

  const universitiesQueryValues = useMemo(
    () =>
      getDividedParams('universities')?.length
        ? getDividedParams('universities')?.map(Number)
        : getParamsFromString('universities', savedSearchParams)?.map(Number),
    [searchParams],
  );

  /**
   * STATE values
   * */
  const SAVED_UNIVERSITY_OFFERS = localStorage.getItem(UNIVERSITY_STORAGE_OFFERS_KEY);
  const [universityStorageOffers, setUniversityStorageOffers] = useState<OfferItem[]>(
    SAVED_UNIVERSITY_OFFERS ? JSON.parse(SAVED_UNIVERSITY_OFFERS) : [],
  );

  const [phrase, setPhrase] = useState('');
  const [offerPage, setOfferPage] = useState<number>(0);
  const [offers, setOffers] = useState<OfferItem[]>([]);
  const [znoScores, setZnoScores] = useState<ZnoScores>({
    data: scoresQueryValues,
    isCompleted: !!scoresQueryValues[100],
  });
  const [universitiesPage, setUniversitiesPage] = useState(0);
  const [universities, setUniversities] = useState<University[]>([]);
  const [isDisableShowMoreBtn, setIsDisableShowMoreBtn] = useState(false);
  const [countOfOffers, setCountOfOffers] = useState<number | undefined>();
  const [isOpenedZnoScoresFilterPopUp, setIsOpenedZnoScoresFilterPopUp] = useState(false);
  const [selectedUniversities, setSelectedUniversities] = useState<number[]>(universitiesQueryValues || []);
  const [selectedSubjects, setSelectedSubjects] = useState<number[]>([]);
  const [filters, setFilters] = useState<{
    sorting: FilterItem;
    education_forms: FilterItem;
    regions: FilterItem;
    specialities: FilterItem;
    offer_types: FilterItem;
    education_bases: FilterItem;
  }>({
    sorting: {
      isOpened: !!filterParams.sorting?.length,
      showMore: sorting.length > 5 ? false : null,
      data: initFilterItem(sorting, filterParams.sorting),
    },
    education_forms: {
      isOpened: !!filterParams.education_forms?.length,
      showMore: education_forms.length > 5 ? false : null,
      data: initFilterItem(education_forms, filterParams.education_forms),
    },
    regions: {
      isOpened: !!filterParams.regions?.length,
      showMore: regions.length > 5 ? false : null,
      data: initFilterItem(regions, filterParams.regions),
    },
    specialities: {
      isOpened: !!filterParams.specialities?.length,
      showMore: specialities.length > 5 ? false : null,
      data: initFilterItem(specialities, filterParams.specialities),
    },
    offer_types: {
      isOpened: !!filterParams.offer_types?.length,
      showMore: offer_types.length > 5 ? false : null,
      data: initFilterItem(offer_types, filterParams.offer_types),
    },
    education_bases: {
      isOpened: !!filterParams.education_bases?.length,
      showMore: education_bases.length > 5 ? false : null,
      data: initFilterItem(education_bases, filterParams.education_bases, BACHELOR_BASE_ARRAY),
    },
  });

  const isSortingByZnoScoresChecked = !!filters.sorting.data.find((obj) => obj.id === SortingTypes.ZNO_SCORES)
    ?.isChecked;

  /**
   * LOGIC FUNCTIONS
   * */
  const handleGettingResults = () => {
    if (isSortingByZnoScoresChecked && !znoScores.isCompleted) {
      setIsOpenedZnoScoresFilterPopUp(true);
    } else {
      navigate({
        pathname: '/offers/result',
        search: location.search,
      });
    }
  };
  const handleSetPhrase = (phrase: string) => setPhrase(phrase);
  const handleChangeOfSelectedUniversities = (updatedData: University) => {
    const idx = selectedUniversities.indexOf(updatedData.id);
    if (idx === -1) {
      setSelectedUniversities([...selectedUniversities, updatedData.id]);
    } else {
      setSelectedUniversities([...selectedUniversities.filter((item) => item !== updatedData.id)]);
    }

    const indexOfFilter = universities.findIndex((obj: University) => obj.id === updatedData.id);
    universities[indexOfFilter] = updatedData;

    setUniversities(universities);
  };

  const handleChangeOfSelectedSubjects = (id: number) => {
    const idx = selectedSubjects.indexOf(id);
    if (idx === -1) {
      setSelectedSubjects((prevState) => [...prevState, id]);
    } else {
      setSelectedSubjects((prevState) => [...prevState.filter((item) => item !== id)]);
    }
  };
  const resetFetchedUniversities = () => {
    setUniversities([]);
    setUniversitiesPage(0);
  };

  const [currentFilters, setCurrentFilters] = useState({});
  const fetchOffersCount = async () => {
    const isZnoSort = filterParams.sorting?.includes(String(SortingTypes.ZNO_SCORES));
    const groupedFilters = groupOfferFiltersRequest({
      offerPage,
      filterParams,
      scoresQueryValues,
      universitiesQueryValues,
      isZnoSort,
    });
    const { pagination } = isZnoSort
      ? await getOffersByZnosCountReq(groupedFilters)
      : await getOffersBySubjectsCountReq(groupedFilters);
    setCountOfOffers(pagination?.count);
  };

  const fetchOffers = async () => {
    const isZnoSort = filterParams.sorting?.includes(String(SortingTypes.ZNO_SCORES));
    const url = isZnoSort ? `${basic.backendUrl}/v1/offers/znos` : `${basic.backendUrl}/v1/offers/subjects`;

    const groupedFilters = groupOfferFiltersRequest({
      offerPage,
      filterParams,
      scoresQueryValues,
      universitiesQueryValues,
      isZnoSort,
    });

    const filtersChanged = JSON.stringify(groupedFilters) === JSON.stringify(currentFilters);

    const response = await fetch(url, {
      method: 'post',
      body: JSON.stringify(groupedFilters),
      headers: {
        'content-type': 'application/json',
      },
    });

    const offersData = await response.json();
    // TODO: needs to fix bug on BE
    const offersToSave = offersData.data.filter((item: OfferItem) => isNaN(parseFloat(item.u_short_name)));

    if (filtersChanged) {
      setOffers(offersToSave);
    } else {
      setOffers((prevState: OfferItem[]) => [...prevState, ...offersToSave]);
    }

    setOfferPage((prevState: number) => prevState + 1);
    setCurrentFilters(groupedFilters);
  };

  const getSearchParams = () => {
    const updatedFilterParams = [];
    for (const [filterType, filterGroup] of Object.entries(filters)) {
      const activeValues: FilterDataItem | number[] = [];
      if (EXTENDED_FILTER_TYPES.includes(filterType as FilterTypes)) {
        for (const filterArray of filterGroup.data) {
          const extendedFilterGroup = filterArray as IFilterItemExtended;

          for (const option of extendedFilterGroup.filters) {
            if (option.isChecked) {
              activeValues.push(option.id);
            }
          }
        }
      } else {
        for (const item of filterGroup.data) {
          if (item.isChecked && !item.isDefault) {
            activeValues.push(item.id);
          }
        }
      }
      if (activeValues.length) {
        updatedFilterParams.push(`${filterType}=${activeValues.join(',')}`);
      }
    }

    const znoScoresValues = Object.values(znoScores.data);

    if (znoScoresValues.length && znoScoresValues.every((e) => e) && isSortingByZnoScoresChecked) {
      updatedFilterParams.push(`scores=${JSON.stringify(znoScores.data)}`);
    }

    if (selectedUniversities.length) {
      updatedFilterParams.push(`universities=${selectedUniversities.join(',')}`);
    }

    if (savedSearchParams != updatedFilterParams.join('&')) {
      localStorage.setItem('searchParams', updatedFilterParams.join('&'));
      setSavedSearchParams(updatedFilterParams.join('&'));
    }

    return updatedFilterParams.join('&');
  };

  const handleResetOffersAndOffersPage = () => {
    setOffers([]);
    setOfferPage(0);
  };

  const handleFiltersChip = () => {
    navigate({
      pathname: '/offers',
      search: location.search,
    });
  };
  const handleGoBack = () => {
    navigate({
      pathname: '/offers/result',
      search: savedSearchParams,
    });
  };
  const fetchUniversities = async () => {
    const queryParams = new URLSearchParams({
      page: String(universitiesPage),
      limit: String(10),
      ...(phrase && { phrase }),
    });

    const response = await fetch(`${basic.backendUrl}/v1/universities/phrase?${queryParams}`);

    const universitiesData = await response.json();
    const universitiesWithCheckOption: University[] = universitiesData.data.map((item: University) => {
      item.isChecked = selectedUniversities.indexOf(item.id) >= 0;
      return item;
    });

    setUniversities((prevState: University[]) => [...prevState, ...universitiesWithCheckOption]);
    setUniversitiesPage((prevState: number) => prevState + 1);
  };
  const manageStorageUniversities = (card: OfferItem) => {
    const isOfferSaved = universityStorageOffers.some((item) => item.id === card.id);
    const updatedStorageOffers = isOfferSaved
      ? universityStorageOffers.filter((item) => item.id !== card.id)
      : [...universityStorageOffers, card];

    setUniversityStorageOffers(updatedStorageOffers);
    localStorage.setItem(UNIVERSITY_STORAGE_OFFERS_KEY, JSON.stringify(updatedStorageOffers));
  };
  const handleClearingOfFilters = () => {
    setZnoScores({
      data: {},
      isCompleted: false,
    });

    setSelectedUniversities([]);
    setSelectedSubjects([]);

    const resetUniversities = [...universities].map((item) => ({ ...item, isChecked: false }));
    setUniversities(resetUniversities);

    setFilters({
      sorting: {
        isOpened: false,
        showMore: sorting.length > 5 ? false : null,
        data: resetFilterItem(sorting, FilterTypes.sorting),
      },
      education_forms: {
        isOpened: false,
        showMore: education_forms.length > 5 ? false : null,
        data: resetFilterItem(education_forms, FilterTypes.education_forms),
      },
      regions: {
        isOpened: false,
        showMore: regions.length > 5 ? false : null,
        data: resetFilterItem(regions, FilterTypes.regions),
      },
      specialities: {
        isOpened: false,
        showMore: specialities.length > 5 ? false : null,
        data: resetFilterItem(specialities, FilterTypes.specialities),
      },
      offer_types: {
        isOpened: false,
        showMore: offer_types.length > 5 ? false : null,
        data: resetFilterItem(offer_types, FilterTypes.offer_types),
      },
      education_bases: {
        isOpened: !!filterParams.education_bases?.length,
        showMore: education_bases.length > 5 ? false : null,
        data: resetFilterItem(education_bases, FilterTypes.education_bases, BACHELOR_BASE_ARRAY),
      },
    });

    setSavedSearchParams('');

    navigate({
      pathname: '/offers',
    });
  };

  const handleChangingOfFilters = (typeOfFilter: FilterTypes, updatedData: IUpdatedFilters) => {
    let updatedFilters;

    if (updatedData.data) {
      if (EXTENDED_FILTER_TYPES.includes(typeOfFilter) && !('filters' in updatedData.data)) {
        let indexOfFilter;
        let newFiltersArr;
        for (let i = 0; i < filters[typeOfFilter].data.length; i++) {
          indexOfFilter = (filters[typeOfFilter].data[i] as IFilterItemExtended).filters.findIndex(
            (obj: IExtendedFilters) => {
              return obj.id === updatedData.data.id;
            },
          );
          if (indexOfFilter !== -1) {
            newFiltersArr = [...(filters[typeOfFilter].data || [])];
            (newFiltersArr[i] as any).filters[indexOfFilter] = updatedData.data;
            break;
          }
        }
        if (newFiltersArr) {
          updatedFilters = {
            ...filters,
            [typeOfFilter]: {
              ...filters[typeOfFilter],
              data: [...newFiltersArr],
            },
          };
        } else {
          updatedFilters = { ...filters };
        }
      } else {
        const indexOfFilter = filters[typeOfFilter].data.findIndex((obj: IFilterItem | IFilterItemExtended) => {
          return obj.id === updatedData.data.id;
        });
        const newFiltersArr = [...filters[typeOfFilter].data];
        (newFiltersArr[indexOfFilter] as IFilterItemExtended | IExtendedFilters) = updatedData.data;

        updatedFilters = {
          ...filters,
          [typeOfFilter]: {
            ...filters[typeOfFilter],
            data: [...newFiltersArr],
          },
        };
      }
    } else {
      updatedFilters = {
        ...filters,
        [typeOfFilter]: {
          ...filters[typeOfFilter],
          ...updatedData,
        },
      };
    }

    setFilters(updatedFilters);
  };
  const handleSelectOfZnoSubjects = (id: number) => {
    const subjectId = Number(Object.keys(znoScores.data).find((e) => +e === id));

    const scores: {
      data: {
        [score: number]: string | null;
      };
      isCompleted: boolean;
    } = {
      data: { ...znoScores.data },
      isCompleted: znoScores.isCompleted,
    };

    switch (true) {
      case !!subjectId: {
        delete scores.data[subjectId];

        break;
      }
      default: {
        scores.data = { ...znoScores.data, [id]: null };
      }
    }

    setZnoScores(scores);
  };
  const handleSelectOfZnoScores = (id: number, score: string | null, isCompleted?: boolean) => {
    setZnoScores({
      data: { ...znoScores.data, [id]: score },
      isCompleted: isCompleted === undefined ? znoScores.isCompleted : isCompleted,
    });
  };

  /**
   * USE EFFECT logic
   */
  useEffect(() => {
    if (isOpenedZnoScoresFilterPopUp) {
      document.querySelector('body')?.classList.add('hidden');
    } else {
      document.querySelector('body')?.classList.remove('hidden');
    }
  }, [isOpenedZnoScoresFilterPopUp]);

  useEffect(() => {
    const searchParams = getSearchParams();
    if ((!location.search && !savedSearchParams) || (location.search && savedSearchParams)) {
      navigate({
        search: `?${searchParams}`,
      });
    }
  }, [filters, znoScores, selectedUniversities]);

  useEffect(() => {
    fetchOffersCount();
  }, [filterParams, scoresQueryValues, universitiesQueryValues]);
  useEffect(() => {
    let isShowButtonDisabled = true;

    switch (true) {
      case getIsFiltersDataChecked(filters):
      case isSortingByZnoScoresChecked:
      case !!selectedUniversities.length: {
        isShowButtonDisabled = false;
        break;
      }
    }

    setIsDisableShowMoreBtn(isShowButtonDisabled);
  }, [isSortingByZnoScoresChecked, znoScores.isCompleted, filters, selectedUniversities]);

  const value = useMemo(
    () => ({
      countOfOffers,
      filters,
      selectedUniversities,
      setSelectedUniversities,
      handleChangingOfFilters,
      znoScores,
      setZnoScores,
      isOpenedZnoScoresFilterPopUp,
      isSortingByZnoScoresChecked,
      handleGettingResults,
      setIsOpenedZnoScoresFilterPopUp,
      isDisableShowMoreBtn,
      handleSelectOfZnoScores,
      handleSelectOfZnoSubjects,
      handleClearingOfFilters,
      handleFiltersChip,
      filterParams,
      universitiesQueryValues,
      scoresQueryValues,
      universities,
      resetFetchedUniversities,
      phrase,
      handleSetPhrase,
      fetchUniversities,
      universityStorageOffers,
      handleChangeOfSelectedUniversities,
      offers,
      fetchOffers,
      handleGoBack,
      manageStorageUniversities,
      handleResetOffersAndOffersPage,
      searchParams,
      selectedSubjects,
      handleChangeOfSelectedSubjects,
    }),
    [
      filters,
      selectedUniversities,
      setSelectedUniversities,
      handleChangingOfFilters,
      znoScores,
      setZnoScores,
      isOpenedZnoScoresFilterPopUp,
      isSortingByZnoScoresChecked,
      handleGettingResults,
      setIsOpenedZnoScoresFilterPopUp,
      isDisableShowMoreBtn,
      handleSelectOfZnoScores,
      handleSelectOfZnoSubjects,
      handleClearingOfFilters,
      handleFiltersChip,
      filterParams,
      universitiesQueryValues,
      scoresQueryValues,
      universities,
      resetFetchedUniversities,
      phrase,
      handleSetPhrase,
      fetchUniversities,
      universityStorageOffers,
      offers,
      fetchOffers,
      handleChangeOfSelectedUniversities,
      handleGoBack,
      manageStorageUniversities,
      countOfOffers,
      handleResetOffersAndOffersPage,
      searchParams,
      selectedSubjects,
      handleChangeOfSelectedSubjects,
    ],
  );

  return (
    // the Provider gives access to the context to its children
    <OffersContext.Provider value={value}>{children}</OffersContext.Provider>
  );
};

export const useOffersContext = () => {
  // get the context
  const context = useContext(OffersContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useOffersContext was used outside of its Provider');
  }

  return context;
};

export { OffersContext, OffersContextProvider };
