import { CheckMarkIcon } from '../../icons/CheckMarkIcon';
import { CheckboxTitle } from '../CheckboxTitle/CheckboxTitle';
import styles from './SubjectCheckbox.module.scss';

export const SubjectCheckbox = ({
  handleZnoScores,
  subjectName,
  isChecked,
}: {
  handleZnoScores: () => void;
  subjectName: string;
  isChecked: boolean;
}) => {
  return (
    <label className={styles.wrap}>
      <input checked={isChecked} type='checkbox' className={styles.inputCheckBox} onChange={handleZnoScores} />
      <CheckMarkIcon className={styles.checkbox} width='10' />
      <CheckboxTitle>{subjectName}</CheckboxTitle>
    </label>
  );
};
