import styles from './OfferCoefficients.module.scss';
import { subjects } from '../../filters/subjects';
import { OfferSubject } from '../../interfaces/filter-item.interface';

export const OfferCoefficients = ({ offerSubjects }: { offerSubjects: OfferSubject[] }) => {
  const sortSubjects = (a: OfferSubject, b: OfferSubject) => {
    if (a.k === 0.1 && b.k !== 0.1) {
      return 1;
    } else if (a.k !== 0.1 && b.k === 0.1) {
      return -1;
    } else {
      return a.p - b.p;
    }
  };
  return (
    <div className={styles.table}>
      <div className={styles.row}>
        <div className={styles.title}>Предмети</div>
        <div className={styles.title}>Коєф.</div>
      </div>
      <div className={styles.contentWrap}>
        {offerSubjects.length &&
          offerSubjects.sort(sortSubjects).map((it: OfferSubject, i: number) => (
            <div key={it.subject_id} className={styles.row}>
              <div>{`${subjects.find((item) => item.id == it.subject_id)?.subjectName}${
                i == 0 || i == 1 ? '*' : ''
              }`}</div>
              <div>{it.k}</div>
            </div>
          ))}
      </div>
    </div>
  );
};
